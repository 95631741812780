import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Row, Col, Container, Card, CardBody } from 'reactstrap';
import { NotFound } from '../errors';
import PageTitle from '../../components/Common/PageTitle';
import { BasicTable } from '../../components/Tables/BasicTable';
import CreateUser from './CreateUser';
import UserMenu from './UserMenu';
import { useUsers } from '../../hooks';
import { withUser } from '../../context/UserContext';

const CreateUserCol = styled(Col)`
  display: grid;
  place-content: flex-end;
`;

const columns = [
  {
    dataField: 'email',
    text: 'Email',
    headerStyle: () => {
      return { width: '27%', backgroundColor: 'white' };
    },
  },
  {
    dataField: 'role',
    text: 'Role',
    headerStyle: () => {
      return { width: '27%', backgroundColor: 'white' };
    },
  },
  {
    dataField: 'phoneNumber',
    text: 'Phone number',
    headerStyle: () => {
      return { width: '27%', backgroundColor: 'white' };
    },
  },
  {
    dataField: 'action',
    text: 'Action',
    formatter: (cell, row) => <UserMenu row={row} />,
    headerStyle: () => {
      return { width: '21%', backgroundColor: 'white' };
    },
  },
];

const Users = ({ user }) => {
  if (!user || user.role !== 'ADMIN') {
    return <NotFound />;
  }

  const { data, isLoading, fetchUsers } = useUsers();

  fetchUsers();

  return (
    <div className="page-content">
      <PageTitle title="Users" />
      <Container fluid>
        <Row>
          <Col xs={10}>
            <h3>Users</h3>
          </Col>
          <CreateUserCol xs={2}>
            <CreateUser />
          </CreateUserCol>
        </Row>
        <Row>
          <Col xl={12}>
            <Card>
              <CardBody>
                <BasicTable
                  wordBreak
                  columns={columns}
                  data={data || []}
                  isLoading={isLoading}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

Users.propTypes = {
  user: PropTypes.object.isRequired,
};

export default withUser(Users);
